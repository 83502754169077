<template>
  <Transition
    :name="position"
  >
    <slot></slot>
  </Transition>
</template>

<script>

export default {
  name: 'ProductItemImgTransition',
  props: {
    // top|bottom|left|right
    position: {
      type: String,
      default: () => 'bottom',
    }
  },
}
</script>

<style lang="less" scoped>


.top-enter-active,
.top-leave-active,
.bottom-enter-active,
.bottom-leave-active,
.left-enter-active,
.left-leave-active,
.right-enter-active,
.right-leave-active {
  transition: all 0.25s ease-out;
}

.top-enter-from,
.top-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

.bottom-enter-from,
.bottom-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.left-enter-from,
.left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.right-enter-from,
.right-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>
