import { createApp } from 'vue'
// import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import { registerBaseContext } from 'public/src/pages/common/common_inject.js'

let SimilarInstance = undefined

export default {
  async showModal(data, extendParams) {
    // 如果组件已渲染，则返回即可
    if (SimilarInstance) {
      return SimilarInstance.showModal(data, extendParams)
    }
    if (typeof window === 'undefined') return
    const { default: infiniteScroll } = await import('@sheinfe/vue-infinite-scroll')
    try {
      const SimilarVue = await import('./similar_products_modal.vue')
      //const SimilarConstructor = Vue.extend(SimilarVue.default)
      const similarApp = createApp(SimilarVue.default)
      similarApp.use(infiniteScroll)
      registerBaseContext(similarApp)
      const instance = similarApp.mount(document.createElement('div'))
      // 要挂载的元素
      const parent = document.body
      // 通过构造函数初始化组件
      // const instance = new SimilarConstructor({
      //   el: document.createElement('div'),
      // })
      // 将元素挂在到parent上面
      parent.appendChild(instance.$el)
      // 将组件实例赋值
      // eslint-disable-next-line require-atomic-updates
      SimilarInstance = instance
      // 展示
      SimilarInstance.showModal(data, extendParams)
    } catch (error) {
      console.log('import similar product vue error', error)
    }
  }
}
