import { transformImg, preloadImgs, template, htmlDecode as htmlDecodeFn } from '@shein/common-function'
import { promotionCenter } from 'public/src/pages/common/promotion'
import atomSer from './interfaceSer'
import { setPretreatInfo, includsPageKey } from './tools'
import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import { abtservice } from 'public/src/services/abt'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { getGoodsUrl as getGoodsUrlComm } from 'public/src/pages/common/utils/index.js'
class GoodsItemInfo {
  constructor({ listLanguage = {}, getGoodsUrl = '', itemConfig } = {}) {
    this.listLanguage = listLanguage
    if (getGoodsUrl) this.getGoodsUrl = getGoodsUrl

    if (typeof window === 'undefined') return
    this.itemConfig = itemConfig || {}
    this.language = gbCommonInfo && gbCommonInfo.language
    this.IS_RW = gbCommonInfo && gbCommonInfo.IS_RW
    this.lang = gbCommonInfo && gbCommonInfo.lang
    this.GB_cssRight = gbCommonInfo && gbCommonInfo.GB_cssRight
    this.csrfToken = gbCommonInfo && gbCommonInfo.csrf_token || ''
    this.getGoodsUrl = getGoodsUrlComm
    this.htmlDecode = (text) => htmlDecodeFn({ text }),
    this.mallInfoForSite = []
    this.atomFields = {}
  }
  /**
   * 获取主商品数据
   */
  async getProductInfo({ goods = [], requestFields = {}, itemConfig = {}, pageKey = '', subPageKey = '', sceneKey = 'TWO_IN_A_ROW', cccParams, caller, isPaid } = {}) {
    if (!goods.length || typeof window === 'undefined') return

    this.fields = requestFields || {}  // 这是一份完整的请求颗粒
    this.itemConfig = itemConfig || {}
    this.pageKey = pageKey
    this.subPageKey = subPageKey
    this.sceneKey = sceneKey
    this.caller = caller
    this.cccParams = cccParams
    this.isPaid = isPaid
    
    this.atomFields = { ...this.fields }
    // promotionCenter促销服务里面请求，故这里删除
    // delete this.atomFields.sheinClubPromotionInfo 
    // delete this.atomFields.newFlashPromotion

    const requestQueue = [
      // 站点mall信息
      getSiteMallInfo(),
      // 商品原子信息
      atomSer.getAtomicInfo({ 
        goods, 
        fields: this.atomFields,
        pageKey,
        subPageKey,
        sceneKey,
        caller,
        cccParams,
        isPaid,
        cb: (goodsItem) => {
          this.dealExt({ goodsItem })
        }
      })
    ]

    // abt
    this.abtInfo = {}
    const newPosKeys = []
    if (includsPageKey({ pageKey: subPageKey || pageKey })) {
      if (requestFields.cccTspBadges) newPosKeys.push('NewPosition')
    }
    if (newPosKeys.join()) {
      requestQueue.push(new Promise(resolve => {
        // eslint-disable-next-line @shein-aidc/abt/abt
        abtservice
          .getUserAbtResult({ newPosKeys })
          .then(abtInfo => {
            this.abtInfo = abtInfo
            resolve()
          })
      }))
    }

    const [ mallInfo ] = await Promise.all(requestQueue)

    this.mallInfoForSite = mallInfo?.mallInfoForSite?.length ? mallInfo.mallInfoForSite : []

    // 促销信息预处理
    await this.getAssociatedPromotionInfo({ goods, caller: this.caller })

    goods.forEach(item => {
      this.dealData({ goodsItem: item })

      setPretreatInfo({ goodsItem: item, field: 'getInfoLoading', value: false })
    })

    return Promise.resolve(goods)
  }
  /**
   * 获取多颜色商品数据 ex: 价格，库存，上下架等
  */
  async getRelateColorProductInfo ({ goods = [], colorAtomFields, pageKey, subPageKey, sceneKey, caller, cccParams, isPaid } = {}) {
    if (!goods.length || typeof window === 'undefined') return

    // 提取多色块
    let relatedColor = []
    goods.forEach(goodsInfo => {
      if (goodsInfo.relatedColor?.length) {
        relatedColor = relatedColor.concat(goodsInfo.relatedColor)
      }
    })

    if (!relatedColor.length) return

    // 获取多色块商品项数据
    Object.assign(this.atomFields, { baseInfo: true })
    const relatedColorInfo = await atomSer.getAtomicInfo({
      goods: relatedColor, 
      fields: colorAtomFields || this.atomFields,
      pageKey: pageKey || this.pageKey,
      subPageKey: subPageKey || this.subPageKey,
      sceneKey: sceneKey || this.sceneKey,
      caller: caller || this.caller,
      cccParams: cccParams || this.cccParams,
      isPaid: isPaid || this.isPaid,
      cb: (goodsItem) => {
        this.dealExt({ goodsItem })
      }
    })

    // 促销信息预处理
    await this.getAssociatedPromotionInfo({ goods: relatedColorInfo, caller: this.caller })

    relatedColorInfo.forEach(relatedGoodsInfo => {
      setPretreatInfo({ goodsItem: relatedGoodsInfo, field: 'getInfoLoading', value: true })
      relatedGoodsInfo.goods_img = transformImg({ img: relatedGoodsInfo.goods_img })

      this.dealData({ goodsItem: relatedGoodsInfo })

      // preload img
      this._preloadImgs(relatedGoodsInfo)
    })
  }
  async getRelatedColorAtomicInfo({ goods, colorAtomFields, pageKey, sceneKey, subPageKey, caller, cccParams, isPaid, mallInfo, abtInfo, promotionInfoFromServer, language }) {
    let relatedColor = []
    goods.forEach(goodsInfo => {
      if (goodsInfo.relatedColor?.length) {
        relatedColor = relatedColor.concat(goodsInfo.relatedColor)
      }
    })

    if (!relatedColor.length) return

    await atomSer.getAtomicInfoNew({
      goods: relatedColor, 
      fields: colorAtomFields,
      pageKey,
      subPageKey,
      sceneKey,
      caller,
      cccParams,
      isPaid,
      mallInfo,
      abtInfo,
      cb: (goodsItem) => {
        this.dealExt({ goodsItem })
      }
    })

    // 新的商品预处理服务 - 目前只有促销
    goods = dealProductsPretreatInfo({
      products: relatedColor, 
      promotionInfoFromServer, 
      language,
    })
  }
  // 处理数据 
  dealData({ goodsItem }) {
    // 售罄
    this.dealSaleout({ goodsItem })
    // 折扣角标初始化
    this.dealUnitDiscount({ goodsItem })
    // 格式化badge
    this.dealBadge({ goodsItem, abtInfo: this.abtInfo })
    // 品牌系列，埋点数据
    this.dealSeriesBrandAnalysis({ goodsItem })
    // 系列 品牌logo
    this.dealSeriesBrandLogo({ goodsItem })
    // 大码
    this.dealPlusSize({ goodsItem })
    // 展示个位数剩余库存,  0 < 库存 < 10
    this.dealLeftStock({ goodsItem })
    // 展示收藏数量
    this.dealLinkQuantity({ goodsItem })
    // 降价标识
    this.dealReducePrice({ goodsItem })
    // 收藏状态
    this.dealWishStatus({ goodsItem })
    // 卖点
    this.dealSellingPoints({ goodsItem })
    // 本地发货标签
    this.dealLocalShipping({ goodsItem })
    // 处理销售属性
    this.dealSellAttr({ goodsItem })
    // 保留款
    this.dealTspLabels({ goodsItem })
    // 橱窗配置数据
    this.dealDisplayWindowInfo({ goodsItem })
  }
  /**
   * preload img
   */
  async _preloadImgs (relatedGoodsInfo) {
    const preloadImgsWithoutLoading = async (imgList, relatedGoodsInfo) => {
      await preloadImgs({ imgs: imgList })
      setPretreatInfo({ goodsItem: relatedGoodsInfo, field: 'getInfoLoading', value: false })
    }

    let imgList = []
    imgList.push(relatedGoodsInfo.goods_img)
    const detailImage = relatedGoodsInfo?.detail_image?.[0]
    const secondImg = detailImage?.medium_image || detailImage // 兼容请求原子detailImage和列表直接返回图片的数据
    // 二图
    if (secondImg && typeof secondImg == 'string') {
      imgList.push(transformImg({ img: secondImg }))
    }
    if (window.requestIdleCallback) {
      window.requestIdleCallback(async () => {
        preloadImgsWithoutLoading(imgList, relatedGoodsInfo)
      })
    } else {
      preloadImgsWithoutLoading(imgList, relatedGoodsInfo)
    }
  }
  /**
   * 获取促销关联信息
  */
  async getAssociatedPromotionInfo ({ goods = [], caller } = {}) {
    // const { sheinClubPromotionInfo, newFlashPromotion } = this.fields
    await promotionCenter.assignItems({ 
      items: goods, 
      isGetLable: true, 
      isGetVipPrice: true, 
      // isGetSheinClubPrice: sheinClubPromotionInfo,
      // isGetNewFlashPromotion: newFlashPromotion, 
      isAppPromotion: true,
      caller
    })
    goods.forEach(item => {
      const { promotionModule, newFlashPromotion } = item
      const { price, id, typeId } = newFlashPromotion || {} // 命中新型闪购价格
      promotionModule?.rawModule?.forEach(promo => {
        const { flash_type } = promo
        switch(Number(promo?.typeId)) {
          case 24: // 限购促销
            promo['limitBuyPromotionTip'] = promo.tips.text
            if (promo.ruleType != 'no_discount') {
              setPretreatInfo({ goodsItem: item, field: 'label', value: 'Limited Stock' })
            }
            break
          case 12: // vip 价格
            item['exclusivePromotionPrice'] = item.vipPrice
            break
          case 29: // 付费会员
            // item['sheinClubPromotionInfo'] = item.sheinClubPromotionInfo 促销服务内部已经混入了sheinClubPromotionInfo
            break
          // case 3: // 限时活动, 展示icon，展示折上折
          //   setPretreatInfo({ goodsItem: item, field: 'limitedoff', value: true })
          //   break
          case 10 || 11: // 10 常规闪购, 11 直播闪购
            if (flash_type == 2 || flash_type == 3) { // 新型闪购 2-新人 3-专属
              if (!newFlashPromotion) return

              let flashTypeText = this.listLanguage.SHEIN_KEY_PC_23047
              if (flash_type == 3) flashTypeText = this.listLanguage.SHEIN_KEY_PC_23051
              setPretreatInfo({ goodsItem: item, field: 'newFlashPromotion', value: { id, typeId, flash_type, price, flashTypeText } })
            } else { // 常规、直播闪购
              setPretreatInfo({
                goodsItem: item,
                field: 'flashsale',
                value: {
                  info: promo,
                  saleProcess: promo.soldNum / promo.buyLimit * 100,
                  message: {
                    soldNum: `${template(promo.soldNum, this.language.SHEIN_KEY_PC_14587)}`,
                    soldPercent: `${(promo.soldNum / promo.buyLimit * 100).toFixed(0)}%`
                  }
                }
              })
            }
            break
          case 8:
            setPretreatInfo({ goodsItem: item, field: 'apponly', value: true })
            break
        }
      })
      setPretreatInfo({ goodsItem: item, field: 'mixPromotionInfo', value: promotionModule.rawModule })
      setPretreatInfo({ goodsItem: item, field: 'promotionReady', value: true })
      setPretreatInfo({ goodsItem: item, field: 'newFlashPromotionReady', value: true })
    })
  }
  /** ext上报信息处理 */
  dealExt ({ goodsItem = null } = {}) {
    if (!goodsItem) return

    const ext = goodsItem.ext
    const d_ext = goodsItem.dynamic_ext
    if (ext) {
      ext.other_ext_mark = Object
        .keys(ext)
        .reduce((curr, next) => {
          if (next != 'rec_mark' && next != 'extra_mark') {
            curr.push(`${next}_${ext[next]}`)
          }
          return curr
        }, [])
        .join('|')
    }
    if (d_ext) {
      d_ext.other_d_ext_mark = Object
        .keys(d_ext)
        .map(_ => `${_}_${d_ext[_]}`)
        .join('|')
    }
  }

  /**
   * 格式化折扣角标
  */
  dealUnitDiscount ({ goodsItem = null } = {}) {
    if (!goodsItem || ((!goodsItem.unit_discount || Number(goodsItem.unit_discount) === 0) && !goodsItem.newFlashPromotion)) return

    let discountInfo = {}

    // 优先取新型闪购折扣
    const newFlashDiscount = goodsItem.newFlashPromotion?.discount_percent
    const discount = newFlashDiscount || goodsItem.unit_discount // 新型闪购折扣率优先于原折扣率
    discountInfo.unitDiscountString = discount ? `${this.lang !== 'il' ? '-' : ''}${discount}%` : ''
    discountInfo.newFlashDiscount = newFlashDiscount

    setPretreatInfo({ 
      goodsItem, 
      field: 'discountInfo', 
      value: { 
        ...discountInfo, 
        show: !!discountInfo.unitDiscountString 
      }
    })
  }

  /**
   * 格式化badge, 位置在图片左下角和商品下方，目前左下方角有new, top rated，商品下方有low in stock，但角标是灵活配置，后期会有增减
  */
  async dealBadge ({ goodsItem = null, abtInfo } = {}) {
    if (!goodsItem || !goodsItem.badges || !this.fields.cccTspBadges) {
      setPretreatInfo({ goodsItem, field: 'badgesReady', value: true })  
      return
    }

    const { P_UPPER_LEFT, P_BOTTOM_LEFT, BELOW_PRICE } = goodsItem.badges
    // const isNewTag = (id) => [70007003, 70007004, 70007005, 70007006].indexOf(id) >= 0
    const isLowInStock = (id) => id == 70007016
    const handlePretreatInfo = (field, positionBadgeData) => {
      const { name, 'name-en': nameEn, backgroundColor, fontColor, id } = positionBadgeData[0]
      const formatNameEn = nameEn?.split(' ')?.join('_')?.toLowerCase() || ''
      const badgeInfo = { text: name, fontColor, backgroundColor, code: `show_label_${formatNameEn}`, isLowInStock: isLowInStock(id) }
      setPretreatInfo({ goodsItem, field, value: badgeInfo })
    }

    // 图片左上角标
    if (P_UPPER_LEFT) {
      let position = 'imgUpperLeft'
      if (abtInfo?.NewPosition?.p?.NewPosition == 'FrontTitle') {
        position = 'frontTitle'
      }
      handlePretreatInfo(position, P_UPPER_LEFT)
    }

    // 图片左下角标
    if (P_BOTTOM_LEFT) {
      handlePretreatInfo('imgBottomLeft', P_BOTTOM_LEFT)
    }
    
    // 商品底部角标
    if (BELOW_PRICE) {
      handlePretreatInfo('goodsBottom', BELOW_PRICE)
    }

    setPretreatInfo({ goodsItem, field: 'badgesReady', value: true })
  }
  /**
   * 处理品牌系列，埋点数据
  */
  dealSeriesBrandAnalysis ({ goodsItem = null, IS_RW = false } = {}) {
    if (!goodsItem) return
    if (typeof window !== 'undefined') {
      IS_RW = this.IS_RW
    }

    const { image_url: seriesLogo, name: seriesName } = goodsItem.series || goodsItem.series_badge || {}
    const { name: brandName, brand_logo: brandLogo } = goodsItem.brand || goodsItem.brand_badge_new || {}
    
    const seriesBadge = !IS_RW && seriesName ? `${seriesName} ` : ''

    // 埋点数据,系列品牌上报, 系列优先于品牌
    let seriesOrBrand = ''
    if (seriesBadge && seriesLogo) {
      seriesOrBrand = `series_${seriesBadge.toLowerCase()}`
    } else {
      if (!IS_RW) {
        if (brandName && brandLogo) seriesOrBrand = `brand_${brandName.toLowerCase()}`
      } else {
        if (goodsItem.isAlone == 1) {
          seriesOrBrand = 'series_romweoriginals'
        } else if (brandName && brandLogo) {
          seriesOrBrand = `brand_${brandName.toLowerCase()}`
        }
      }
    }
    
    setPretreatInfo({ goodsItem, field: 'seriesOrBrandAnalysis', value: seriesOrBrand })
  }
  /**
   * 系列 or 品牌logo展示
  */
  async dealSeriesBrandLogo ({ goodsItem = null } = {}) {
    if (!goodsItem) return

    const series = goodsItem.series || goodsItem.series_badge || {}
    const brand = goodsItem.brand || goodsItem.brand_badge_new || {}

    if (!this.IS_RW) {  
      // 系列
      const seriesLogo = this.GB_cssRight ? series.corner_img_right : series.corner_img_left
      if (seriesLogo) setPretreatInfo({ goodsItem, field: 'seriesLogo', value: seriesLogo })

      // 品牌  只取左边
      const brandLogo = brand.corner_img_left
      if (brandLogo) setPretreatInfo({ goodsItem, field: 'brandLogo', value: brandLogo })
    } else {
      if (series.image_url) {
        setPretreatInfo({ goodsItem, field: 'seriesOrBrandLogoBottom', value: series.image_url })
      } else if (goodsItem.isAlone && Number(goodsItem.isAlone) === 1) {
        setPretreatInfo({ goodsItem, field: 'romweoriginals', value: this.listLanguage.SHEIN_KEY_PC_17141 })
      }
    }
  }
  /**
   * 生成详情页地址
  */
  dealGoodsDetailUrl ({ goodsItem = null, query = {} } = {}) {
    if (!goodsItem) return
    let goodsDetailUrl = this.getGoodsUrl?.(goodsItem.goods_url_name, goodsItem.goods_id, goodsItem.cat_id)
    // query
    if (query && Object.prototype.toString.call(query) === '[object Object]') {
      let queryArr = []
      Object.keys(query).forEach((field) => {
        if(query[field]) queryArr.push(`${field}=${query[field]}`)
      })
      if (queryArr.length > 0) goodsDetailUrl += `?${queryArr.join('&')}`
    }
    goodsItem['detail_url'] = goodsDetailUrl
  }
  /** 大码 */
  dealPlusSize ({ goodsItem = null }) {
    if (!goodsItem) return

    if (goodsItem.is_show_plus_size && Number(goodsItem.is_show_plus_size) !== 0) {
      if (!this.IS_RW && !goodsItem?.pretreatInfo?.seriesLogo) {  // 大码兜底图 shein
        const src = this.GB_cssRight ? 'curve-plus-r-52ab3e3dc6.png' : 'curve-plus-b50bac3f89.png'
        setPretreatInfo({ goodsItem, field: 'seriesLogo', value: `/she_dist/images/plusSize/${src}` })
        setPretreatInfo({ goodsItem, field: 'plusSizeDefault', value: true })
      }
      setPretreatInfo({ goodsItem, field: 'plusSize', value: this.listLanguage.SHEIN_KEY_PC_16973 })
    }
  }
  /** 售罄 */
  dealSaleout ({ goodsItem = null }) {
    if (!goodsItem) return
    const { stock, attrValueId, sizeStock, sku_code } = goodsItem
    const _stock = sku_code || +attrValueId ? sizeStock : stock
    if (+goodsItem.is_on_sale === 0 || +_stock === 0) {
      setPretreatInfo({
        goodsItem,
        field: 'saleout',
        value: {
          show: true,
          message: this.listLanguage.SHEIN_KEY_PC_14617
        }
      })
    }
  }
  /** 剩余库存 */
  dealLeftStock ({ goodsItem = null }) {
    if (!goodsItem || !(this.itemConfig.showLeftStock || this.itemConfig.showLeftStockShein)) return

    const { stock, attrValueId, sizeStock, sku_code } = goodsItem
    const _stock = sku_code || +attrValueId ? sizeStock : stock
    if (+_stock > 0 && +_stock < 10) {
      setPretreatInfo({ goodsItem, field: 'stockLeft', value: template(_stock, this.listLanguage.SHEIN_KEY_PC_16400) })
    }

    setPretreatInfo({ goodsItem, field: 'stockLeftReady', value: true })
  }
  /** 收藏数量 */
  dealLinkQuantity ({ goodsItem = null }) {
    if (!goodsItem || !this.itemConfig.showLikeQuantity) return

    let { count } = goodsItem
    if (count == 0) {
      count = 1
    }
    if (count && count > 999) {
      count = Math.floor((count / 1000) * 10) / 10 + 'k'
    }

    setPretreatInfo({ goodsItem, field: 'likeQuantity', value: { quantity: count, icon: 'sui_icon_save_completed_16px', tip: `${count} ${this.listLanguage.SHEIN_KEY_PC_15512}` } })
  }
  /** 降价标识 */
  dealReducePrice ({ goodsItem = null }) {
    if (!this.itemConfig.showReducePrice) return

    if (goodsItem.reducePrice?.amountWithSymbol) {
      setPretreatInfo({ goodsItem, field: 'reducePrice', value: template(goodsItem.reducePrice.amountWithSymbol, this.listLanguage.SHEIN_KEY_PC_16553) })
    }
    setPretreatInfo({ goodsItem, field: 'reducePriceReady', value: true })
  }
  /** 收藏 */
  dealWishStatus ({ goodsItem = null }) {
    if (!this.itemConfig.showAddWishlist) return

    setPretreatInfo({ goodsItem, field: 'addWishlistResult', value: goodsItem.wishStatus })
  }
  // 卖点
  dealSellingPoints ({ goodsItem = null }) {
    if (!goodsItem?.tag_result || !this.itemConfig.showSellingPoint) {
      return setPretreatInfo({ goodsItem, field: 'sellingPointsReady', value: true })
    }
  
    let sellingPointsBySort = []
    for(let tag of goodsItem.tag_result) {
      for (let tagValue of tag.tags) {
        tagValue.tag_id = tag.tag_id
        sellingPointsBySort.push(tagValue)
      }
    }
    sellingPointsBySort = sellingPointsBySort.sort((a, b) => a.tag_sort - b.tag_sort)
    
    setPretreatInfo({ goodsItem, field: 'sellingPoints', value: sellingPointsBySort })
    setPretreatInfo({ goodsItem, field: 'sellingPointsReady', value: true })
  }
  // 本地发货标签
  dealLocalShipping ({ goodsItem = null }) {
    if (!goodsItem || !this.mallInfoForSite?.length) {
      return setPretreatInfo({ goodsItem, field: 'localMallReady', value: true })
    }

    let mallTagsInfo = {}

    const { language_mall_name, mall_tags } = this.mallInfoForSite.find(mallInfo => mallInfo.mall_code == goodsItem.mall_code) || {}
    
    if (!mall_tags) {
      setPretreatInfo({ goodsItem, field: 'mallTagsInfo', value: mallTagsInfo })
      setPretreatInfo({ goodsItem, field: 'localMallReady', value: true })
      return
    }

    const isOverSeaMall = mall_tags.includes('4')
    const isLocalMall = mall_tags.includes('5')

    Object.assign(mallTagsInfo, { localMall: isLocalMall && !isOverSeaMall, mall_tags, language_mall_name })
    
    setPretreatInfo({ goodsItem, field: 'mallTagsInfo', value: mallTagsInfo })
    setPretreatInfo({ goodsItem, field: 'localMallReady', value: true })
  }
  // 销售属性(场景： 收藏)
  dealSellAttr({ goodsItem }) {
    if (!goodsItem || !this.itemConfig.showSellAttr || !goodsItem.sku_attr_list) return
    const sellattrs = goodsItem.sku_attr_list.reduce((arr, item) => {
      item.is_main === 0 && arr.push(item.attr_value_multi_name)
      return arr
    }, [])
    setPretreatInfo({ goodsItem, field: 'sellAttr', value: sellattrs.join('/') })
  }

  // 加车弹窗同步到列表收藏状态
  syncWishStatus ({ goodsItem, params: { status } }) {
    setPretreatInfo({ goodsItem: goodsItem, field: 'addWishlistResult', value: status })
  }
  // 保留款, 跟价款
  dealTspLabels ({ goodsItem }) {
    if (!goodsItem) return

    // const saleout = goodsItem.pretreatInfo?.saleout?.show

    // if (this.itemConfig.showBestDealLabel) {
    //   setPretreatInfo({ 
    //     goodsItem, 
    //     field: 'showBestDeal', 
    //     value: !!(!this.IS_RW && !saleout && goodsItem.realTimeTspLabels?.['3598'])
    //   })
    // }
    // if (this.itemConfig.showFollowLabel) {
    //   setPretreatInfo({ 
    //     goodsItem,
    //     field: 'followLabelVisible', 
    //     value: !!(!this.IS_RW && !saleout && goodsItem.realTimeTspLabels?.['3658'])
    //   })
    // }
  }
  // 橱窗配置数据
  dealDisplayWindowInfo({ goodsItem }) {
    if (!goodsItem) return

    // 用户评论关键词
    let showUserCommentKeywordsLabel = this.itemConfig.showUserCommentKeywordsLabel
    let userCommentKeywordsLabelInfo = goodsItem.userCommentKeywordsLabel ?? {}
    let userCommentKeywordsLabelInfolabelLangEn = userCommentKeywordsLabelInfo?.labelLangEn ?? ''

    if (showUserCommentKeywordsLabel && userCommentKeywordsLabelInfolabelLangEn) {
      const getAna = `show_review_label_${userCommentKeywordsLabelInfolabelLangEn}`

      setPretreatInfo({ 
        goodsItem, 
        field: 'userCommentKeywordsLabel', 
        value: { 
          ...userCommentKeywordsLabelInfo, 
          ana: getAna
        }
      })
    } else if (this.itemConfig.showUserBehaviorLabel) {
      // 用户行为标签
      const userBehaviorLabel = goodsItem.pcStandardView?.userBehaviorLabels?.[0]
      
      if (userBehaviorLabel) {
        setPretreatInfo({ 
          goodsItem, 
          field: 'userBehaviorLabel', 
          value: { 
            ...userBehaviorLabel, 
            ana: ['show_behavior_label', userBehaviorLabel.labelId, userBehaviorLabel.labelLangEn?.split(' ')?.join('_')].join('_') 
          }
        })
      }
    }

    // 腰带
    if (this.itemConfig.showBeltLabel && !goodsItem.pretreatInfo?.showBestDeal) {
      const beltLabels = goodsItem.pcStandardView?.beltLabels
      if (beltLabels) {
        setPretreatInfo({ 
          goodsItem, 
          field: 'beltLabel', 
          value: { 
            ...beltLabels, 
            ana: `belt_CCC${beltLabels.labelId}`
          }
        })
      }
    }

    // 榜单
    if (this.itemConfig.showRankingList) {
      const rankingList = goodsItem.pcStandardView?.rankingList
      if (rankingList) {
        setPretreatInfo({ 
          goodsItem, 
          field: 'rankingList', 
          value: { 
            ...rankingList, 
            ana: `show_rank_type_${rankingList.rankingType}`
          }
        })
      }
    }
  }

  /* ---------------------- 下面是接口请求 ---------------------- */
  // 加车
  async addToBag ({ goodsItem = null, selectedSize = '', skuCode = '', hasAttr = false } = null) {
    if (!goodsItem) return
    let returnInfo = {}

    // 当前商品正在请求中
    if (!!goodsItem?.pretreatInfo?.addToBagLoading) {
      returnInfo['type'] = 'requesting'
      return returnInfo
    }

    // 没有选择尺码 埋点上报
    if (hasAttr && !selectedSize) {
      returnInfo['type'] = 'noSelectSize'
      returnInfo['response'] = { code: 1 }
      return returnInfo
    }

    // 接口参数
    let addBagForm = {
      goodsId: goodsItem.goods_id,
      mallCode: goodsItem.mall_code,
      quantity: 1,
    }
    if (skuCode) addBagForm.skuCode = skuCode

    const traceid = gbExposeTraceid('getComponent', {
      componentName: 'goodslist'
    })
    if (traceid) {
      addBagForm.traceId = traceid
    }

    // add to bag loading
    setPretreatInfo({ goodsItem: goodsItem, field: 'addToBagLoading', value: true })
    setPretreatInfo({ goodsItem: goodsItem, field: 'addToBagResult', value: '' })

    const result = await atomSer.addToBag(addBagForm)

    if (!result) {
      returnInfo['type'] = 'requestError'
      return returnInfo
    }

    const { code, info, tips } = returnInfo['response'] = result || {}

    if (code == 0 && info) {
      returnInfo['type'] = 'success'
    } else {
      returnInfo['type'] = 'businessError'
      returnInfo['businessErrorCode'] = Number(code)
    }

    tips && setPretreatInfo({ goodsItem: goodsItem, field: 'addToBagResult', value: tips })
    setPretreatInfo({ goodsItem: goodsItem, field: 'addToBagLoading', value: false })
    return returnInfo
  }

  // 添加心愿单
  async addWish ({ goodsItem = null, params, config }) {
    return atomSer.addWish({ goodsItem, params, config })
  }

  // 删除心愿单
  async cancelWish({ goodsItem = null, params }) {
    return atomSer.cancelWish({ goodsItem, params })
  }
}

export {
  GoodsItemInfo
}
