import i18n from 'public/src/pages/common/biz_helper/i18n'

export default {
  data() {
    return {
      itemLanguage: {},
      constantData: {},
    }
  },
  created() {
    const {
      IMG_LINK = this.IMG_LINK,
      LAZY_IMG = this.LAZY_IMG,
      LAZY_IMG_SQUARE = this.LAZY_IMG_SQUARE,
      GB_cssRight = this.GB_cssRight,
      IS_RW = this.IS_RW,
      PUBLIC_CDN = this.PUBLIC_CDN,
      LAZY_IMG_SOLID_COLOR = this.LAZY_IMG_SOLID_COLOR,
      lang = this.lang
    } = typeof window === 'undefined' ? {} : gbCommonInfo

    this.constantData = Object.assign({}, this.constantData, {
      IMG_LINK,
      LAZY_IMG,
      LAZY_IMG_SQUARE,
      GB_cssRight,
      IS_RW,
      PUBLIC_CDN,
      LAZY_IMG_SOLID_COLOR,
      lang
    })
  },
  async mounted() {
    const language = (await i18n.loadByQueue('item')) || {}
    this.itemLanguage = Object.assign({}, this.itemLanguage, language)
  },
}
