import schttp from 'public/src/services/schttp'

/**
 * 
 * @param {Promise} promiseRequest promise请求
 * @param {Object} param1
 *    @param {String} symbol 缓存键
 *    @param {Number} expire 缓存时效
 * @returns 
 */
const cacheAsync = (promiseRequest, { symbol, expire = 600000 }) => {
  const cache = new Map()
  const never = Symbol()
  // params: 请求的入参
  return async (params) => {
    return new Promise((resolve, reject) => {
      // 可以提供键值
      symbol = symbol || params
      let cacheCfg = cache.get(symbol)
      if (!cacheCfg) {
        cacheCfg = {
          hit: never,
          exector: [{ resolve, reject }],
        }
        cache.set(symbol, cacheCfg)
      } else {
        // 命中缓存
        if (cacheCfg.hit !== never) {
          return resolve(cacheCfg.hit)
        }
        cacheCfg.exector.push({ resolve, reject })
      }

      const { exector } = cacheCfg
      
      // 处理并发，在请求还处于pending过程中就发起了相同的请求
      // 拿第一个请求
      if (exector.length === 1) {
        const next = async () => {
          try {
            if (!exector.length) return
            const response = await promiseRequest(params)
            // 如果成功了，那么直接resolve掉剩余同样的请求
            while (exector.length) {
              exector.shift().resolve(response) 
            }
            // 缓存结果
            cacheCfg.hit = response
            // 超时不来取缓存则过期
            expire && setTimeout(() => cache.delete(symbol), expire)
          } catch (error) {
            // 如果失败了 那么这个promise的则为reject
            const { reject } = exector.shift()
            reject(error)
            next() // 失败重试，降级为串行
          }
        }
        next()
      }
    })
  }
}

const queryMallInfo = () => {
  return schttp({
    method: 'POST',
    url: '/api/common/mallInfo/post'
  })
}

export const getSiteMallInfo = cacheAsync(queryMallInfo, { symbol: 'siteMallInfo' })
