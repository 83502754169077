<template>
  <ProductItemImgTransition>
    <div
      v-show="isActive"
      class="recommend-panel__wrap j-da-event-box"
      :data-goods-id="item.goods_id"
      :data-src-identifier="srcIdentifier"
      da-expose-code="recommendPanelCode"
    >
      <!-- title -->
      <div
        v-if="isActive"
        class="recommend-panel__top"
      >
        <div
          v-expose="getAnalysisData({ id: isFindSimilar ? '2-3-24' : '2-3-17', type: 'title' })"
          v-tap="getAnalysisData({ id: isFindSimilar ? '2-3-23' : '2-3-18', type: 'title' })"
          class="recommend-panel__top-title"
        >
          <span @click="showDialog">{{ title }}</span>
          <IconsMoreRightRw
            v-if="!gbCssRight"
            @click="showDialog"
          />
          <IconsMoreLeftRw
            v-else
            @click="showDialog"
          />
        </div>
        <IconsCloseAlert
          v-if="showCloseIcon"
          da-event-expose="2-3-19"
          da-event-click="2-3-20"
          :data-goods-id="item.goods_id"
          :data-st="TRIGGER_EVENT_MAP[triggerEvent]"
          @click="close"
        />
      </div>

      <!-- swiper -->
      <div
        :class="[
          swiperClassName,
          'recommend-panel__swiper'
        ]"
      >
        <!-- 推荐商品 -->
        <div
          class="j-da-event-box"
          :data-abt-info="listPoskey"
          :data-promo-list="(catInfo.type === 'picks' && catInfo.picks_id) || ''"
          :data-promo-list-type="
            (catInfo.type === 'picks' && catInfo.sub_type) || ''
          "
          data-module="true"
          :data-src-module="isFindSimilar ? '' : 'feedback'"
          :data-active-from="isFindSimilar ? 'similar_sold_out_pre' : 'realtime_feedback'"
          :data-page-num="catInfo.page || 1"
          :data-poskey="listPoskey"
          :data-page-sort="catInfo.sort || 0"
          :data-tag_ids="catInfo.tag_ids"
          :data-attr_str="catInfo.attr_ids"
          :data-cat="catInfo.child_cat_id"
          :data-min-pri="catInfo.min_price"
          :data-max-pri="catInfo.max_price"
          :data-src-identifier="isFindSimilar ? '' : srcIdentifier"
          :data-beacon="0"
        >
          <swiper-container
            ref="swiperEl"
            init="false"
            destroy-on-disconnected="false"
            :dir="gbCssRight ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(obj, index) in (isActive ? showList : [])"
              :key="obj.goods_id"
            >
              <ProductCard
                v-if="newProductCard"
                :item="obj" 
                :index="index"
                :open-target="openTarget ? '_blank' : '_self'"
                :config="panelItemShowConfig"
                :constant-data="constantData"
                :language="itemLanguage"
                :list-abt-result="listAbtResult"
                :bottom-info-style="{minHeight: 0, marginTop: '-6px'}"
              />
              <ProductItem
                v-else
                :item="obj" 
                :index="index"
                :open-target="openTarget ? '_blank' : '_self'"
                :config="panelItemShowConfig"
                :constant-data="constantData"
                :language="itemLanguage"
                :list-abt-result="listAbtResult"
                :bottom-info-style="{minHeight: 0, marginTop: '-6px'}"
              />
            </swiper-slide>

            <swiper-slide>
              <div
                v-expose="getAnalysisData({ id: isFindSimilar ? '2-3-24' : '2-3-21', type: 'view_more' })"
                v-tap="getAnalysisData({ id: isFindSimilar ? '2-3-23' : '2-3-22', type: 'view_more' })"
                class="goods-item-more"
                @click="showDialog"
              >
                <img
                  :src="showList[0] && showList[0].goods_img"
                  alt=""
                />
                <div>
                  <span class="goods-item-more__icon">
                    <IconsMoreRightRw 
                      v-if="!gbCssRight"
                    />
                    <IconsMoreLeftRw v-else />
                  </span>
                  <br />
                  <span class="goods-item-more__text">{{ language.SHEIN_KEY_PC_25881 }}</span>
                </div>
              </div>
            </swiper-slide>
          </swiper-container>
        </div>

        <!-- prev -->
        <span
          v-show="showPrevBtn"
          class="recommend-swiper-button recommend-prev swiper-button-prev"
        >
          <IconsMoreLeftRw v-if="!gbCssRight" />
          <IconsMoreRightRw v-else />
        </span>
      
        <!-- next -->
        <span
          v-show="showNextBtn"
          class="recommend-swiper-button recommend-next swiper-button-next"
        >
          <IconsMoreRightRw v-if="!gbCssRight" />
          <IconsMoreLeftRw v-else />
        </span>
      </div>

      <!-- 弹窗 -->
      <RecommendDialog
        v-if="visibleDialog"
        v-model:visible="visibleDialog"
        :item="item"
        :title="title"
        :trigger-event="triggerEvent"
        :constant-data="constantData"
        :atom-params="atomParams"
        :adp="adp"
        :language="language"
        :use-bff-api="useBffApi"
        :list-poskey="listPoskey"
        :list-abt-result="listAbtResult"
        :show-config="dialogItemShowConfig"
      />
    </div>
  </ProductItemImgTransition>
</template>

<script>
import RecommendDialog from './RecommendDialog'
import ProductItemImgTransition from './ProductItemImgTransition.vue'
import ProductItem from '../ProductItem.vue'
import ProductCard from 'public/src/pages/components/productItemV3/MultipleImageRowCard.vue'
import { IconsCloseAlert, IconsMoreLeftRw, IconsMoreRightRw } from '@shein-aidc/icons-laptop'
import { daEventCenter } from 'public/src/services/eventCenter'
import { TRIGGER_EVENT_MAP } from '../utils/index'
import SimilarPopover from 'public/src/pages/common/similar_products'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { Navigation } from 'swiper/modules'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()


const { GB_cssRight } = gbCommonInfo
const daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'RecommendPanel',
  directives: { tap, expose },
  emits: ['close', 'recPanelMounted'],
  components: {
    ProductItem,
    ProductCard,
    RecommendDialog,
    IconsMoreLeftRw,
    IconsMoreRightRw,
    IconsCloseAlert,
    ProductItemImgTransition
  },
  props: {
    listAbtResult: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    itemLanguage: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => ([]),
    },
    constantData: {
      type: Object,
      default: () => ({}),
    },
    atomParams: {
      type: Object,
      default: () => ({}),
    },
    catInfo: {
      type: Object,
      default: () => ({})
    },
    // 弹窗productItem展示内容配置
    dialogItemShowConfig: {
      type: Object,
      default: () => ({})
    },
    // 推荐面板productItem展示内容配置
    panelItemShowConfig: {
      type: Object,
      default: () => ({})
    },
    listPoskey: {
      type: String,
      default: '',
    },
    openTarget: {
      type: Boolean,
      default: true,
    },
    isFindSimilar: {
      type: Boolean,
      default: false,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    newProductCard: {
      type: Boolean,
      default: false,
    },
    // 是否走BFF中间层接口
    useBffApi: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    return {
      isActive: false, // 是否激活展示实时推荐反馈面板
      recommendSwiper: null,
      activeIndex: 0,
      visibleDialog: false, // 展示弹窗
      showPrevBtn: false,
      showNextBtn: true,
      gbCssRight: GB_cssRight,
      triggerEvent: null,
      recommendList: [],
      goodsItemInfo: null,
      TRIGGER_EVENT_MAP
    }
  },
  computed: {
    adp () {
      return this.showList?.map(item => item.goods_id).join(',')
    },
    showList() {
      if (this.config.productImpressions) {
        return this.list?.slice(0, this.config.productImpressions) || []
      } else {
        return this.list
      }
    },
    title() {
      return this.config.labelLang || this.language.SHEIN_KEY_PC_25880
    },
    swiperClassName() {
      return `recommend-panel__swiper-${this?.item?.goods_id || Date.now()}`
    },
    goodsListParams() {
      return this.list.map((item, index) => ({
        sku: item.goods_sn,
        spu: item.productRelationID || item.goods_sn,
        goodsId: item.goods_id,
        originPrice: item?.retailPrice?.usdAmount || '',
        price: item?.salePrice?.amount || '',
        mallCode: item.mall_code,
        recMark: item.ext?.rec_mark || '',
        extraMark: item.ext?.extra_mark || '',
        index
      }))
    },
    srcIdentifier() {
      return `on=${this.item.goods_id}'st=${TRIGGER_EVENT_MAP[this.triggerEvent]}`
    }
  },
  mounted() {
    // 收藏的场景需要立即触发，因为有找相识的弹框。
    // 暂时在列表场景进行延迟触发
    if (window.pageTypeForMonitor === 'product-list') {
      setTimeout(() => {
        this.initSwiper() // 延迟执行 拆分任务（如发现问题，可立即测回）
      }, 1000)
    } else {
      this.initSwiper()
    }

    this.$emit('recPanelMounted')
    this.goodsItemInfo = new GoodsItemInfo({
      listLanguage: this.language,
    })
  },
  beforeUnmount () {
    this.recommendSwiper?.destroy()
  },
  methods: {
    initSwiper () {
      const self = this
      const swiperEl = this.$refs.swiperEl
      if (!swiperEl) return
      const swiperParams = {
        modules: [Navigation],
        direction: 'horizontal',
        // slidePrevClass: `.${this.swiperClassName} .swiper-button-prev`,
        // slideNextClass: `.${this.swiperClassName} .swiper-button-next`,
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 6,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        roundLengths: true,
        navigation: {
          prevEl: `.${this.swiperClassName} .swiper-button-prev`,
          nextEl: `.${this.swiperClassName} .swiper-button-next`,
        },
        on: {
          slideChangeTransitionEnd: (swiper)=> {
            self.showPrevBtn = swiper.activeIndex !== 0 
            self.showNextBtn = swiper.isEnd !== true
          }
        }
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.recommendSwiper = swiperEl.swiper
    },
    showDialog() {
      if (this.isFindSimilar) {
        this.findSimilar()
      } else {
        this.visibleDialog = true
      }
    },
    findSimilar() {
      const { goods_id, cat_id } = this.item
      SimilarPopover.showModal(
        { goods_id, cat_id },
        { similarFrom: 'out_of_stock' }
      )
    },
    async show(triggerEvent) {
      window.$rp = this
      this.isActive = true
      this.triggerEvent = triggerEvent

      this.$nextTick(() => {
        this.showPrevBtn = false
        this.showNextBtn = true
        this.recommendSwiper.slideTo(0)
        this.initExpose()
      })
    },
    // 曝光埋点
    initExpose() {
      daEventExpose.subscribe({
        keycode: `recommendPanelCode\`${ this.panelItemShowConfig.daEventExposeId || '' }|2-3-17|2-3-19|2-3-21`,
        type: 'list',
      })
    },
    getAnalysisData({ id, type }) {
      if (this.isFindSimilar) {
        return {
          id,
          data: {
            activity_from: 'similar_sold_out_pre',
            goods_id: this.item.goods_id,
            similar_from: 'out_of_stock',
            is_out_of_stock: 1,
            style: 'page',
            button_form: type
          }
        }
      } else {
        return {
          id
        }
      }
    },
    // 关闭商品推荐
    close() {
      this.isActive = false
      this.$emit('close')
    },
  }
}
</script>

<style lang="less" scoped>

.recommend-panel {
  &__wrap {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 8px 6px 6px 6px;
    position: absolute;
    bottom: 0;
    z-index: @zindex-hack;

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :deep(.product-card__prices-info) {
      height: auto;
    }
  }

  &__top {
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    width: 100%;
    .flexbox();
    .space-between();
    .align-center();
    .pack-center();

    .recommend-panel__top-title {
      flex: 1;
      overflow: hidden;
      .flexbox();
      .align-center();

      span {
        font-size: 14px;
        font-weight: 600;
        max-width: calc(100% - 15px);
        display: inline-block;
        cursor: pointer;
        .text-overflow();
      }
    }

    .sui-icons {
      fill: #FFFFFF;
      cursor: pointer;
    }
    
    >i {
      width: 10px;
    }
  }

  &__swiper {
    position: relative;
    margin-top: 8px;

    // .swiper-wrapper {
    //   width: 100%;
    //   height: 100%;
    //   .flexbox()
    // }

    .goods-item-more {
      position: relative;
      background: rgba(255,255,255, 0.08);

      img {
        opacity: 0;
        width: 100%;
      }

      >div {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        flex-direction: column;
        .flexbox();
        .align-center();
        .pack-center();

        .goods-item-more__icon {
          border: 1px solid #fff;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          display: inline-block;
        }

        .sui-icons {
            fill: #fff;
            width: 8px;
        }

        .goods-item-more__text {
          color: #fff;
          font-weight: 500;
          font-size: 10px;
          margin-top: 5px;
          text-align: center;
        }
        
      }
    }

    .recommend-swiper-button {
      position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        margin-top: 0;
        cursor: pointer;
        border-radius: 50%;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        user-select: none;
        .flexbox();
        .align-center();
        .pack-center();
    }
    .recommend-prev {
      left: 4px;
    }
    .recommend-next {
      right: 4px;
    }
  }
}
</style>

<style lang="less">
.recommend-panel__wrap .S-product-item {
  background-color: rgba(0, 0, 0, 0.8);
}
.recommend-panel__wrap .S-product-item__price .last-price {
  overflow-wrap: break-word;
}
</style>
